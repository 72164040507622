$button-width: 44px;
$button-height: 44px;
$icon-count: 2;

.pswp__bg {
    background-color: #fff;
}

.pswp__top-bar {
    background-color: transparent;
    clear: both;
}
    // .pswp__notch {
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     width: $button-width * $icon-count;
    //     margin: 0 auto;
    //     font-size: 0;
    //     letter-spacing: 0;
    //     word-spacing: 0;

    //     &.hidden {
    //         display: none;
    //     }
    // }

.pswp__actions {
    float: right;
    text-align: right;
    width: $button-width;
    font-size: 0;
    letter-spacing: 0;
    word-spacing: 0;
}

// .pswp__notch, 
.pswp__actions {
    .pswp__button {
        background: none;
        background-image: none;
        float: none;
        position: relative;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.5);
        width: $button-width;
        height: $button-height;
        opacity: 0.75;

        &:focus, &:hover {
            opacity: 1;
            background-color: #fff;
        }

        &:before {
            color: #000;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            top: 10px;
            left: 0;
            font-size: 16px;
            text-align: center;
            margin: auto;
            width: $button-width;
            height: $button-height;
            position: absolute;
        }
    }
    .pswp__button--fs:before {
        content: "\f065";
    }
    .pswp__button--download:before {
        content: "\f019";
    }
    .pswp__button--close:before {
        content: "\f00d";
    }
    .pswp__button--play:before {
        content: "\f04b";
    }
    .pswp__button--play.pause:before {
        content: "\f04c";
    }
}

.pswp__ui--over-close .pswp__button--close {
    opacity: 0.75;
}

@media(orientation: portrait) {
    .pswp__actions {
        width: 100%;
        .pswp__button {
            float: right; // inverts the order compared to landscape
        }
    }
}

.pswp__actions.video {
    .pswp__button {
        background-color: rgba(255, 255, 255, 1);
        opacity: 1;
    }
    .pswp__button--play, .pswp__button--download {
        display: none;
    }
}

// @media(max-width: 991.98px) {
//     .pswp__button--play {
//         display: none!important;
//     }
// }

// New spinner
.pswp__img--placeholder--blank {
    text-align: center;

    .fa-spin {
        margin-top: 46px;
    }
}

.pswp__preloader {
    .fa-spin {
        color: #e7796b
    }
}