.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;

  .frame {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 250px;
      
      button.link {
          margin: 0 4px 4px 0;
          
          .mount1 {
              overflow: hidden;
              z-index: 100;

              &:hover {
                  background: none !important;
                  backface-visibility: hidden;
                  -webkit-backface-visibility: hidden;
                  -moz-backface-visibility: hidden;
                  -ms-backface-visibility: hidden;
                  opacity: 1;
                  filter: Alpha(opacity=100);
                  z-index: 102;
                  position: relative;
              }

               .mount2 {
                  padding-top: 56%;
                  overflow: hidden;
                  width: 100%;
                  position: relative;

                  .mount3 {
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      width: 100%;
                      height: 100%;
                      position: absolute;

                      .video-overlay {
                          color: #fff;
                          opacity: 0.95;
                          font-size: 18px;
                          position: absolute;
                          left: calc(50% - 18px);
                          top: calc(50% - 18px);
                          z-index: 101;

                          .fa-play {
                              color: #222;
                              font-size: 14px;
                              left: 1px;
                          }
                      }

                      img {
                          transition: all .3s;
                          
                          &:hover, &.hover {
                              -ms-transform: scale(1.08);
                              -webkit-transform: scale(1.08);
                              transform: scale(1.08);
                          }
                      }
                  }
              }
          }
      }
  }
}

@media(max-width: 274px) {
  .frame {
      width: 100%;
  }
}

@media(min-width: 275px) {
  .frame {
      width: 50%;
  }
}

@media(min-width: 641px) {
  .frame {
      width: 33.3333%;
  }
}

@media(min-width: 1052px) {
  .frame {
      width: 25%;
  }
}

.video-container {
  justify-content: center; // horizontal align
  align-items: center; // vertical align
  display: flex;
  height: 100%;
  background-color: #fff;

  video {
      max-width: 100%;
      max-height: 100%;
  }
}

#hidden-download-link {
    visibility: hidden;
    display: none;
}