$font-default: Lato, sans-serif;

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

embed,
iframe,
img,
object {
    max-width: 100%
}

img {
    height: auto
}

body,
button,
input,
select,
textarea {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: .7em
}

sub,
sup {
    font-size: .7em;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.25em
}

sub {
    bottom: -.125em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input[type=checkbox],
input[type=radio] {
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid silver;
    padding: .25 gr .35 gr .75 gr
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

html {
    font-family: $font-default;
    font-size: 93.75%;
    line-height: 1.7
}

body,
button,
input,
select,
textarea {
    color: #3d3d3d
}

.error404 .page-title,
.page .entry-title,
.search-no-results .page-title,
.search:not(.search-no-results) .entry-title,
.single .entry-title,
.site-name,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-default;
    text-transform: uppercase;
    clear: both;
    letter-spacing: .2em;
    margin: 0;
    line-height: 1.414
}

.site-name,
h1 {
    font-size: 1.917rem
}

.page .entry-title,
.search:not(.search-no-results) .entry-title,
.single .entry-title,
h2 {
    font-size: 1.663rem
}

.error404 .page-title,
.search-no-results .page-title,
h3 {
    font-size: 1.442rem
}

h4 {
    font-size: 1.25rem
}

h5 {
    font-size: .94rem
}

h6 {
    font-size: .94rem;
    text-transform: none;
    letter-spacing: .05em
}

hr {
    background-color: #e8e3e5;
    border: 0;
    height: 1px;
    margin-bottom: 1vr
}

figure {
    margin: 0
}

p {
    margin-top: 0;
    margin-bottom: 1.663rem
}

cite,
dfn,
em,
i {
    font-style: italic
}

blockquote {
    position: relative;
    margin-top: 1.663rem;
    margin-bottom: 1.663rem;
    padding: 1.25rem 1.663rem;
    margin-left: -1.663rem;
    font-style: italic;
    font-size: 1.25rem;
    letter-spacing: .01em
}

blockquote:after {
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    border-left: .3rem solid #ffd5d1
}

blockquote p {
    margin: 0
}

address {
    margin-bottom: 2.211rem
}

pre {
    background: #fcf8f8;
    font-family: "Courier 10 Pitch", Courier, monospace;
    margin-bottom: 2.211rem;
    padding: 3.911rem;
    max-width: 100%;
    overflow: auto
}

code,
kbd,
tt,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace
}

abbr,
acronym {
    border-bottom: 1px dotted #e8e3e5;
    cursor: help
}

ins,
mark {
    background: #fff9c0;
    text-decoration: none
}

big {
    font-size: 1.663rem
}

a {
    color: #3d3d3d;
    text-decoration: none;
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

a:visited {
    color: #3d3d3d
}

a:active,
a:focus,
a:hover {
    color: #636363
}

a:active,
a:hover {
    outline: 0
}

.single .entry-footer a {
    color: #e7796b
}

.single .entry-footer a:focus,
.single .entry-footer a:hover {
    outline: 0;
    color: #dc3d29
}

.single .category-links a,
.single .entry-meta a {
    text-transform: uppercase;
    font-family: $font-default;
    color: #e7796b;
    letter-spacing: .1em
}

.site-content ol,
.site-content ul {
    margin-bottom: 2.211rem
}

.site-content ol li,
.site-content ul li {
    margin-bottom: .707rem
}

.site-content ul {
    list-style: disc
}

.site-content ol {
    list-style: decimal
}

.site-content li>ol,
.site-content li>ul {
    margin-bottom: 0;
    margin-left: .5em
}

.site-content dt {
    font-weight: 700
}

.site-content dd {
    margin: 0 1.663rem 1.663rem
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 1.3rem;
    width: 100%
}

thread th {
    border-bottom: 2px solid #bbb;
    padding-bottom: .3rem
}

td,
th {
    padding: 0;
    vertical-align: top
}

th {
    padding: .85rem;
    text-align: left
}

tr {
    border-bottom: 1px solid #eee
}

td {
    padding: .3rem
}

td:first-child,
th:first-child {
    padding-left: 0
}

td:last-child,
th:last-child {
    padding-right: 0
}

.button,
.footer-widget .search-submit,
form button,
form input[type=button],
form input[type=reset],
form input[type=submit],
.search-submit,
.site-content .nf-element[type=button],
.widget .search-submit {
    border: 0;
    letter-spacing: .1em;
    font-family: $font-default;
}

.button,
.footer-widget .search-submit,
form button,
form input[type=button],
form input[type=reset],
form input[type=submit],
.search-submit,
.site-content .nf-element[type=button],
.widget .search-submit {
    background-color: #fcf7f6;
    border-radius: 2px;
    text-transform: uppercase;
    padding: .707rem 2.211rem;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-box-shadow: 0 1px 7px 0 rgba(99, 61, 54, .15);
    box-shadow: 0 1px 7px 0 rgba(99, 61, 54, .15)
}

.button:active,
.button:focus,
.button:hover,
.footer-widget .search-submit:active,
.footer-widget .search-submit:focus,
.footer-widget .search-submit:hover,
form button:active,
form button:focus,
form button:hover,
form input[type=button]:active,
form input[type=button]:focus,
form input[type=button]:hover,
form input[type=reset]:active,
form input[type=reset]:focus,
form input[type=reset]:hover,
form input[type=submit]:active,
form input[type=submit]:focus,
form input[type=submit]:hover,
.search-submit:active,
.search-submit:focus,
.search-submit:hover,
.site-content .nf-element[type=button]:active,
.site-content .nf-element[type=button]:focus,
.site-content .nf-element[type=button]:hover,
.widget .search-submit:active,
.widget .search-submit:focus,
.widget .search-submit:hover {
    background-color: #e7796b;
    color: #fdfdfd
}

input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea {
    color: #3d3d3d;
    border: 0;
    border-bottom: 1px solid #878585;
    padding: .94rem;
    background-color: #fcfcfc;
    border-radius: 1px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

input[type=color]:focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=datetime]:focus,
input[type=email]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=range]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
textarea:focus {
    color: #151515;
    border-bottom-color: #e7796b;
    outline: 0
}

select {
    border: 1px solid #3d3d3d
}

textarea {
    width: 100%
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute!important;
    height: 0;
    width: 0;
    overflow: hidden
}

.screen-reader-text:focus {
    z-index: 100000;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    clip: auto!important;
    display: block;
    font-weight: 700;
    height: auto;
    left: 5px;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto
}

#content[tabindex="-1"]:focus {
    outline: 0
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 1.663rem
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.663rem
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.posts-navigation .nav-links,
.site-content,
.site-footer__widgets,
.site-info {
    max-width: 1086px;
    margin: 0 auto
}

@media screen and (max-width:1024px) {
    .posts-navigation .nav-links,
    .site-content,
    .site-footer__widgets,
    .site-info {
        max-width: 724.3620000000001px
    }
}

@media screen and (max-width:540px) {
    .posts-navigation .nav-links,
    .site-content,
    .site-footer__widgets,
    .site-info {
        max-width: 362.72400000000005px
    }
}

body .site {
    min-height: 75vw;
    padding-bottom: 3.911rem
}

.site-content {
    padding-bottom: 3.911rem
}

.site-content__inner {
    background-color: #fdfdfd;
    width: 90%;
    width: calc(100% - 5.882rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: 6.919rem;
    padding-right: 6.919rem
}

.site-content__inner .entry-thumbnail,
.site-content__inner .full-width,
.site-content__inner .fullwidth {
    max-width: unset;
    width: calc(100% + 19.72rem);
    margin-left: -9.86rem
}

@media screen and (max-width:1024px) {
    .site-content__inner {
        padding-left: 1.663rem;
        padding-right: 1.663rem;
        width: calc(100% - 3.326rem)
    }
    .site-content__inner .full-width,
    .site-content__inner .fullwidth {
        width: calc(100% + 6.652rem);
        margin-left: -3.326rem
    }
}

@media screen and (max-width:540px) {
    .site-content__inner {
        padding-left: 0;
        padding-right: 0;
        width: 93%
    }
    .site-content__inner .full-width,
    .site-content__inner .fullwidth {
        width: 100%;
        margin: 0
    }
}

.site-footer {
    background-color: #fcf7f6;
    padding: 2.211rem 1.663rem
}

.site-info {
    font-size: 0.8rem;
    letter-spacing: .02em;
    font-family: $font-default;
    text-transform: uppercase
}

@media screen and (min-width:641px) {
    .site-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
    .site-info p {
        margin: 0
    }
    .site-info .site-info__author {
        margin-left: auto
    }
}

@media screen and (min-width:1025px) {
    .site-menu .menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 6rem;
        text-transform: uppercase;
        letter-spacing: .1em
    }
    .site-menu ul {
        padding: 0
    }
    .site-menu .menu>.menu-item:not(:last-child) {
        margin-right: 3vw
    }
    .site-menu .menu-item-has-children {
        position: relative
    }
    .site-menu .sub-menu a {
        padding: .75rem
    }
    .site-menu .menu>li>.sub-menu {
        z-index: 199999;
        position: absolute;
        min-width: 15rem;
        top: 3.911rem;
        left: 0;
        background-color: #fdfdfd;
        border-top: 4px solid #e7796b;
        -webkit-box-shadow: 0 3px 6px 2px rgba(36, 36, 36, .13);
        box-shadow: 0 3px 6px 2px rgba(36, 36, 36, .13)
    }
    .site-menu .sub-menu .sub-menu {
        margin-left: 1rem;
        border-left: 1px solid #fcf8f8;
        margin-bottom: .75rem
    }
}

.site-menu .site-menu-toggle {
    display: none
}

@media screen and (max-width:1024px) {
    .site-menu__inner {
        z-index: 199999;
        position: fixed;
        background-color: #fdfdfd;
        padding: 2.211rem;
        right: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        display: none
    }
    .admin-bar .site-menu__inner {
        top: 46px
    }
    .site-menu__inner.is-open,
    .site-menu__inner.is-open .menu>ul {
        display: block
    }
    .site-menu {
        clear: both
    }
    .site-menu .menu {
        font-size: 1.2rem;
        font-weight: 700
    }
    .site-menu .menu>ul {
        max-width: 20rem;
        margin-left: auto;
        margin-right: auto
    }
    .site-menu .sub-menu {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        font-weight: 400;
        border-left: 2px solid #fcf7f6;
        padding-left: 1.663rem
    }
    .site-menu ul {
        padding-left: 0
    }
    .site-menu ul li {
        position: relative;
        margin: 0 1px 0 0
    }
    .site-menu ul li:focus>ul,
    .site-menu ul li:hover>ul {
        left: auto
    }
    .site-menu a {
        display: inline-block;
        width: 100%;
        padding: 0.5rem 0;
        text-decoration: none
    }
    .site-menu .menu-item-has-children {
        min-width: 14rem
    }
    .site-menu .menu-menu-container {
        padding-top: 3.911rem;
        max-width: 30rem;
        display: block;
        margin-left: auto;
        margin-right: auto
    }
    .site-menu .site-menu-toggle {
        border: 0;
        padding: 1.25rem;
        display: block;
        background-color: #fcf8f8;
        letter-spacing: .1em;
        font-family: $font-default;
        text-transform: uppercase;
        margin-left: auto;
        margin-right: auto;
        width: 100%
    }
    .site-menu .site-menu-toggle--close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%
    }
    .site-menu .site-menu-toggle--close span {
        font-size: 1.7rem;
        line-height: 0;
        position: relative;
        top: .2rem;
        margin-left: .3rem
    }
}

.site-menu {
    background-color: #fcf8f8;
    margin-bottom: 5.202rem;
    font-size: 0.85rem;
    letter-spacing: .02em
}

.site-menu a {
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

.site-menu .current-menu-item>a {
    color: #e7796b;
}

.site-menu a:focus,
.site-menu a:hover {
    color: #e7796b;
    outline: 0
}

.site-menu .is-open>ul {
    display: block
}

.site-menu .menu {
    font-family: $font-default;
}

.site-menu ul {
    text-align: left;
    list-style: none;
    margin: 0
}

.site-menu ul ul {
    display: none
}

.site-menu a {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.site-menu .menu-item-has-children>a::after {
    content: "â–¾";
    margin-left: 1em
}

.site-menu .is-open>a::after {
    content: 'â–¿'
}

.site-menu+.site-content {
    padding-top: 1.663rem
}

.site-menu__message {
    padding: 1.25rem;
    font-size: .75rem;
    text-align: right
}

.site-menu__message h1 {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em
}

.site-menu__message a {
    display: inline-block;
    color: #e7796b
}

.site-header {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 5.202rem
}

.site-branding {
    text-align: center
}

.logo-container {
    display: block
}

.logo-container img {
    display: block;
    width: auto
}

.site-name {
    margin: 0;
    padding: 0;
    font-size: 2.941rem
}

.comment-navigation,
.paging-navigation,
.post-navigation,
.posts-navigation {
    font-family: $font-default;
    letter-spacing: .1em
}

@media screen and (min-width:641px) {
    .comment-navigation .nav-links,
    .paging-navigation .nav-links,
    .post-navigation .nav-links,
    .posts-navigation .nav-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
    .comment-navigation .nav-links>div,
    .paging-navigation .nav-links>div,
    .post-navigation .nav-links>div,
    .posts-navigation .nav-links>div {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 12rem
    }
    .layout--grid .comment-navigation .nav-links>div,
    .layout--grid .paging-navigation .nav-links>div,
    .layout--grid .post-navigation .nav-links>div,
    .layout--grid .posts-navigation .nav-links>div {
        text-align: center
    }
    .comment-navigation .nav-next,
    .comment-navigation .nav-previous,
    .paging-navigation .nav-next,
    .paging-navigation .nav-previous,
    .post-navigation .nav-next,
    .post-navigation .nav-previous,
    .posts-navigation .nav-next,
    .posts-navigation .nav-previous {
        margin-right: 1.663rem;
        margin-left: 1.663rem
    }
    .comment-navigation .nav-next,
    .paging-navigation .nav-next,
    .post-navigation .nav-next,
    .posts-navigation .nav-next {
        text-align: right
    }
}

.comment-navigation .nav-links span,
.paging-navigation .nav-links span,
.post-navigation .nav-links span,
.posts-navigation .nav-links span {
    display: block;
    text-transform: uppercase;
    color: #878585;
    font-size: .75rem
}

.comment-navigation a,
.paging-navigation a,
.post-navigation a,
.posts-navigation a {
    text-transform: uppercase;
    font-weight: 700;
    display: block
}

.comment-navigation .nav-links>div,
.paging-navigation .nav-links>div,
.post-navigation .nav-links>div,
.posts-navigation .nav-links>div {
    position: relative
}

.comment-navigation .nav-links a::after,
.paging-navigation .nav-links a::after,
.post-navigation .nav-links a::after,
.posts-navigation .nav-links a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

@media screen and (max-width:540px) {
    .comment-navigation .nav-links>div,
    .paging-navigation .nav-links>div,
    .post-navigation .nav-links>div,
    .posts-navigation .nav-links>div {
        margin-bottom: 1.25rem
    }
}

.post-navigation .nav-links {
    padding: 1.663rem 0;
    border-top: double 4px #e8e3e5;
    border-bottom: double 4px #e8e3e5
}

.post-navigation .nav-next,
.post-navigation .nav-previous {
    margin: 0
}

@media lap {
    .post-navigation .nav-previous {
        margin-right: 1.663rem
    }
}

.posts-navigation {
    padding: 3.911rem 0;
    background-color: #fdfdfd;
    margin-top: 3.911rem;
    margin-bottom: -3.911rem
}

.posts-navigation .nav-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left
}

.posts-navigation a {
    color: #878585
}

.posts-navigation a:hover {
    color: #3d3d3d
}

.posts-navigation div+.nav-next {
    text-align: right
}

.image-circle {
    border-radius: 50%
}

.error404 .site-content,
.search-no-results .site-content {
    background-color: #fdfdfd
}

.error404 .site-content__inner,
.search-no-results .site-content__inner {
    padding: 15vh 1.663rem
}

.error404 .page-content,
.search-no-results .page-content {
    text-align: center;
    max-width: 33em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.663rem
}

.error404 .page-content p,
.search-no-results .page-content p {
    margin-bottom: 2.941rem
}

.error404 .page-header,
.search-no-results .page-header {
    margin-bottom: 1.663rem
}

.error404 .page-title,
.search-no-results .page-title {
    text-align: center
}

.page-header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 3.911rem
}

.page-title {
    width: 100%;
    margin: 0;
    font-size: 1.663rem;
    word-break: break-word
}

.archive-description {
    font-size: 1.25rem
}

.site-content .nf-field-label label,
.site-content label.wpforms-field-label {
    display: block;
    text-transform: uppercase;
    font-family: $font-default;
    font-weight: 700;
    font-size: .75rem
}

.site-content {
    background-color: #fdfdfd
}

.site-content__inner {
    padding-top: 5.202rem
}

.page .entry-title {
    text-align: center;
    margin-bottom: 3.911rem
}

.entry-content::after {
    content: "";
    display: table;
    clear: both
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
    margin-top: 2.941rem;
    margin-bottom: 1.663rem
}

.entry-header {
    margin-bottom: 1.663rem
}

.featured-image {
    margin-bottom: 2.211rem
}

.featured-image img {
    display: block
}

.sticky .entry-inner {
    border-bottom: 4px solid #e7796b
}

.sticky-label {
    position: absolute;
    top: 0;
    right: 0;
    padding: .531rem 1.25rem;
    background-color: #e7796b;
    color: #fdfdfd;
    font-family: $font-default;
    text-transform: uppercase;
    font-size: .707rem;
    letter-spacing: .15em
}

.search:not(.search-no-results) .site-content {
    background-color: #fdfdfd
}

.search:not(.search-no-results) .site-content__inner {
    padding-top: 5.202rem
}

.search:not(.search-no-results) .entry-title {
    text-align: left;
    margin: 0
}

.search:not(.search-no-results) article {
    margin-bottom: 2.211rem;
    border-bottom: 1px solid #e8e3e5;
    padding-bottom: 2.211rem
}

.search:not(.search-no-results) .page-title {
    color: #878585;
    font-family: $font-default;
    font-size: 1.25rem
}

.search:not(.search-no-results) .page-title span {
    color: #3d3d3d;
    font-weight: 700;
    border-bottom: #e7796b 2px solid;
    display: inline-block;
    padding-left: .531rem;
    padding-right: .531rem
}

label+.search-submit {
    margin-left: 1.663rem
}

.single .navigation,
.single .post {
    margin-bottom: 5.202rem
}

@media screen and (min-width:641px) {
    .single .entry-thumbnail+.site-content__inner {
        margin-top: -10rem;
        padding-top: 0;
        position: relative
    }
    .single .entry-thumbnail+.site-content__inner:after,
    .single .entry-thumbnail+.site-content__inner:before {
        content: '';
        background-color: #fdfdfd;
        width: 51%;
        position: absolute;
        height: 3.911rem;
        top: -3.856rem;
        display: block
    }
    .single .entry-thumbnail+.site-content__inner:before {
        left: 0;
        -webkit-mask-image: radial-gradient(circle 20px at 0 0, transparent 0, transparent 43px, #000 44px);
        mask-image: radial-gradient(circle 20px at 0 0, transparent 0, transparent 43px, #000 44px)
    }
    .single .entry-thumbnail+.site-content__inner:after {
        right: 0;
        -webkit-mask-image: radial-gradient(circle 20px at 100% 0, transparent 0, transparent 43px, #000 44px);
        mask-image: radial-gradient(circle 20px at 100% 0, transparent 0, transparent 43px, #000 44px)
    }
}

@media screen and (max-width:540px) {
    .single .entry-thumbnail {
        margin-bottom: 2.941rem
    }
}

.single .category-links {
    margin-bottom: .94rem;
    font-weight: 400
}

.single .entry-header {
    margin-bottom: 3.911rem
}

.single .category-links,
.single .entry-title {
    text-align: center
}

.single .entry-footer {
    font-family: $font-default;
    font-weight: 400;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #878585;
    letter-spacing: .1em;
    font-size: .75rem
}

.single .entry-footer:before {
    content: '';
    display: block;
    width: 4.5rem;
    border-top: 2px solid #e7796b;
    margin-bottom: 2.211rem
}

.single .post figure {
    width: auto!important
}

.site-footer__widgets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3.911rem;
    width: 100%
}

.footer-widget,
.widget {
    margin-bottom: 2.211rem;
    padding-bottom: 2.211rem
}

.footer-widget ol,
.footer-widget ul,
.widget ol,
.widget ul {
    padding: 0;
    list-style-type: none
}

.footer-widget ol li,
.footer-widget ul li,
.widget ol li,
.widget ul li {
    display: block
}

.footer-widget a,
.widget a {
    font-weight: 400;
    font-family: $font-default;
}

.footer-widget select,
.widget select {
    width: 100%;
    border: 1px solid #e8e3e5;
    outline: 0
}

.footer-widget .widget-title,
.widget .widget-title {
    font-size: 1.4rem;
    margin-bottom: 1.663rem
}

.footer-widget .search-form,
.widget .search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.footer-widget .search-submit,
.widget .search-submit {
    margin-left: 1.25rem
}

.widget_categories .children {
    padding-top: 0
}

.widget_nav_menu .sub-menu,
.widget_pages .children {
    margin-bottom: 2.211rem
}

.widget_nav_menu a,
.widget_pages a {
    text-decoration: none
}

.widget_nav_menu li,
.widget_pages li {
    padding: 0
}

.widget_meta li {
    padding: 0
}

.widget_search input[type=search] {
    width: 100%;
    border: 1px solid #e8e3e5
}

.site-content .nf-field-label label,
.site-content label.wpforms-field-label {
    margin-bottom: .94rem
}

.site-content .nf-form-fields-required {
    display: none
}

.site-content .nf-error .ninja-forms-field {
    border: none;
    border-bottom: 1px solid red
}

#__bs_notify__ {
    top: 90%!important;
    right: 50%!important;
    border-radius: 7px!important;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    background-color: rgba(0, 0, 0, .3)!important
}

button.link {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    display: inline;
    color: #3d3d3d;
  
    // &:hover {
    //   text-decoration: underline;
    // }
}