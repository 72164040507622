body.root-home {
    // hide scrollbars
    body::-webkit-scrollbar {
        display: none;
    }
    body {
        overflow: -moz-scrollbars-none;
    }
    #page.site {
        min-height: auto;
        background-image: none;
    }
}

body.collection-home {
    // hide scrollbars
    body::-webkit-scrollbar {
        display: none;
    }
    body {
        overflow: -moz-scrollbars-none;
    }
    // site layout
    #page.site {
        padding-bottom: 3.911rem;
    }
    @media (max-width: 1024px) {
        #page.site {
            background-size: cover;
            min-height: calc(100vh - 0px);
            background-position: center top;
        }
    }
    // home page logo
    .site-branding .logo-container {
        width: 38vw;
        max-width: 650px;
    }
    // no content on homepage
    #primary.site-content {
        display: none;
    }
    // footer
    footer.site-footer {
        display: none;
    }
}

/* Root home page */
.collection-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 auto;

    .collection-item {
        flex-basis: 50%;
        max-width: 46%;
        margin: 0 2% 2%;

        h5 {
            text-align: center;
        }
    }

    @media (max-width: 650px) {
        .collection-item {
            max-width: 100%;
            margin: 0 10% 10%;
            flex-basis: 100%;
        }
    }
}