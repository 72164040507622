body.collection-boda {
  &.collection-home {
    #page.site {
      background-image: url(/images/boda-bg-photo.jpg);
    }

    @media (max-width: 1024px) {
      #page.site {
        background-image: url(/images/boda-bg-mobile.jpg);
      }
    }
  }
}

body.collection-bautizo {
  &.collection-home {
    #page.site {
      background-image: url(/images/bautizo-bg-photo.jpg);
    }

    @media (max-width: 1024px) {
      #page.site {
        background-image: url(/images/bautizo-bg-mobile.jpg);
      }

      .site-header {
        position: absolute;
        bottom: 0;
        right: 50%;

        .site-branding .logo-container {
          position: relative;
          right: -50%;
        }
      }
    }
  }

  .site-branding .logo-container {
    width: 28vw!important;
    max-width: 550px!important;
  }
}