@import '~photoswipe/dist/photoswipe.css';
@import '~photoswipe/dist/default-skin/default-skin.css';
@import 'photoswipe';
@import 'base';
@import 'home';
@import 'gallery';
@import 'collections';

$bg-color: #fcf8f8;

// site layout
body, body .site {
    background-color: $bg-color;
}

#page.site {
    min-height: 67vw;
    max-width: 1920px;
    margin: 0 auto;
    background-image: url(/images/bg-plain.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: scroll;
}

@media (min-width: 1025px) {
    #page.site {
        background-position: center top 90px;
    }
}

.site-menu {
    margin-bottom: 0.5rem;
}

#site-menu-open,
#site-menu-close {
    outline: none;
}

// mobile menu
@media (max-width: 1024px) {
    .site-menu .site-menu-toggle {
        text-transform: uppercase;
    }
    .site-menu .site-menu-toggle--close {
        text-align: right;
    }
    .site-menu__inner {
        position: static;
        top: auto;
        right: auto;
        height: auto;
        width: auto;
        background-color: $bg-color;
        padding: 0.5rem 2.11rem 4rem;
        overflow-y: auto;
    }
    .menu-main-container {
        max-width: 145px;
        left: 11px;
        position: relative;
        margin: 0 auto;
    }
    .site-menu {
        font-size: 0.9rem;
    }
    .site-menu .menu {
        font-size: 0.9rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }
}

// logo
.site-branding .logo-container {
    min-width: 300px;
    width: 38vw;
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
}

// header sizes at various screen sizes
@media (max-width: 1024px) {
    .site-branding .logo-container {
        width: 60vw !important;
    }
    .site-header {
        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
    }
    .site-content__inner {
        padding-top: 2.5rem;
    }
    .page .entry-title {
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
}

@media (min-width: 1025px) {
    .site-header {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .site-content__inner {
        padding-top: 3rem;
    }
    .page .entry-title {
        font-size: 1.4rem;
        margin-bottom: 2.5rem;
    }
}

// footer
body .site {
    padding-bottom: 0
}

footer.entry-footer .edit-link {
    display: none;
}

@media (max-width: 1024px) {
    footer.site-footer {
        padding: 1.2rem 1.5rem 0.9rem;
    }
}
// footer text
.site-info .site-info__author {
    text-transform: none;
    text-align: center;
    margin: 0 auto;
}
